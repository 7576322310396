.otherInput {
    margin-top: 1rem;
    margin-left: 1.5rem;
}

.main {
    legend {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
    }
}
