@use '../../components/icons';
@use '../../components/colors';

.main {
    position: sticky;
    top: 0;
    left: 0;
    // Need to set z-index to make sure this appears above other elements
    z-index: 1;

    :global {
        .header {
            background-color: colors.$brandBlue;
            &.nonProd {
                background-color: colors.$brandOrange;
            }

            .spacerColumn {
                padding: 0;
            }

            .menuColumn {
                padding: 0;
            }

            .searchColumn {
                padding: 0.5rem;
            }

            .logo {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                display: block;

                svg {
                    height: 2rem;
                }
            }

            h1 {
                white-space: nowrap;
                font-size: 1rem;
                line-height: 1rem;
                margin-top: 1rem;
                font-weight: 600;
                color: #fff;
            }

            .link {
                padding: 0 0 0 1.5rem;
                background-image: icons.$iconBackArrow;
                background-size: 1rem;
                background-position: left 0.25rem;
                background-repeat: no-repeat;
            }
        }
    }
}
