.advisement {
    display: inline-block;
    color: #000;
    background: #cccccc;
    padding: 1rem;
    width: 100%;

    &.safety {
        color: orange;
        background: #333;
    }

    &.tip {
        background: #bbffbb;
    }
}

.advisementGroup {
    margin-bottom: 1rem;

    legend {
        font-weight: 600;
        font-size: 1rem;
        padding-top: 0;
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .advisement {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;

        &:first-of-type {
            padding-top: 1rem;
        }

        &:last-of-type {
            padding-bottom: 1rem;
        }
    }
}