@use '../../components/icons';
@use '../../components/colors';

.dayDivider {
    &:first-child {
        :global {
            & th.day {
                &.day {
                    padding-top: 0;
                }
            }
        }
    }

    :global {
        & th.day {
            &.day {
                padding-bottom: 0;
                padding-top: 1rem;
            }

            color: #505357;
            font-size: 0.9rem;
            border-bottom: 1px solid #505357;
            font-weight: 600;
        }
    }
}
