.main {
    margin-top: 1rem;

    &:first-of-type {
        margin-top: 0;
    }

    label {
        width: 100%;
    }

    legend {
        font-size: 1rem;
        margin-bottom: 0.25rem;
    }
}

.options {
    :global(.form-check) {
        &:first-of-type {
            margin-top: 0;
        }
        label {
            font-weight: normal;
        }
        margin-top: 0.5rem;
    }

    :global(.form-check-inline) {
        label {
            min-width: 5rem;
        }
        margin-top: 0;
    }
}
