.main {
    scrollbar-gutter: stable;
    overflow: auto;

    :global {
        .problemHistoryBar {
            margin-top: -1rem;
            margin-bottom: 1rem;
        }

        .phone,
        .time {
            white-space: nowrap;
        }

        .tab-content {
            min-height: 25rem;
            margin-top: 0.5rem;
        }

        // TODO: this should go within the details component
        .detailsTable {
            .hasNotes {
                td {
                    padding-bottom: 0.3rem;
                }
            }

            .notes {
                td {
                    padding-top: 0.3rem;
                    border-top: none;
                }
            }
        }
    }
}
