@use '../../colors.scss';

.indented {
    padding-left: 2rem;
}

.outage {
    font-weight: 600;

    :global(.simpleBar) {
        span {
            background-color: #406426;
        }
    }
}

.validEstimate {
    margin-top: -0.75rem;
    font-size: 0.9rem;

    :global(.simpleBar) {
        span {
            background-color: colors.$activeBlue;
        }
    }
}

.noEstimate {
    margin-top: -0.75rem;
    font-size: 0.9rem;

    :global(.simpleBar) {
        span {
            background-color: #ff6600;
        }
    }
}

.expiredEstimate {
    margin-top: -0.75rem;
    font-size: 0.9rem;

    :global(.simpleBar) {
        span {
            background-color: #800000;
        }
    }
}

.nonOutage {
    font-weight: 600;

    :global(.simpleBar) {
        span {
            background-color: #00917c;
        }
    }
}

.countColumn {
    text-align: right;
    width: 6rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.barColumn {
    padding-top: 0.4rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}
