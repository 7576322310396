@use '../../../../../components/colors.scss';

.premiseDetailTooltip {
    :global(.popover) {
        --bs-popover-border-color: #0072ce;
    }

    &.inactive, &.warning {
        :global(.popover) {
            --bs-popover-bg: #ffcccc;
            --bs-popover-border-color: #700;
        }
    }
}

.premiseMarker {
    .marker {
        margin-top: 0.4rem;
        border-radius: 50%;
        background-color: colors.$activeBlue;
        border: 0.1rem solid #000;
        font-weight: bold;
        color: white;
        display: inline-block;
        text-align: center;
        width: 2rem;
        height: 2rem;
        line-height: 1.8rem;

        &.small {
            margin-top: 0;
            width: 1rem;
            height: 1rem;
            line-height: 0.8rem;
            border-width: 0;
            border-style: none;
        }

        &.inactive {
            background-color: #777;
        }

        &.warning {
            background-color: #700;
        }

        a {
            border: none;
            text-decoration: none;
        }
    }
}
