@use '../../../../../components/colors.scss';

.main {
    // arcgis-map {
    //     display:  block;
    //     height: 20rem;
    //     border: 1px solid black;
    // }
    legend {
        font-size: 1rem;
        font-weight: 600;
    }
}

.resultsList {
    height: 70vh;
    overflow-y: auto;
}

.premiseSearchResultRow {
    td {
        vertical-align: top;
        padding: 0.5rem;

        &:nth-child(2) {
            width: 100%;
        }
    }
}
