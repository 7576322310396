@use '../../icons';
@use '../../colors';

.main {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;

    background-color: #999;

    :global {
        .popover-body {
            padding: 0.5rem;
        }

        .simpleBar {
            .outages {
                background-color: #900;
            }

            &.empty span {
                min-width: 0;
            }
        }
    }
}
