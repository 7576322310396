@use '../colors.scss';

.tableCell {
    width: 100%;
}

.main {
    width: 100%;
    text-align: left;
    height: 0.7rem;
    line-height: 0.7rem;

    & :global(.simpleBar) {
        height: 100%;
        line-height: 0.7rem;

        span {
            display: inline-block;
            height: 100%;

            // Default to a small minimum width
            min-width: 0.2rem;
            line-height: 0.7rem;
        }
    }
}
