.messageDetails {
    label {
        display: block;
        margin-bottom: 1rem;
    }

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    :global(.nav) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}
