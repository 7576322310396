.problemHistoryBar {
    position: relative;
    width: 100%;

    tr,
    td {
        margin: 0;
        padding: 0;
    }

    :global {
        td {
            background-color: #dddddd;

            .history-inactive-date,
            .history-active-date {
                height: 0.75rem;
            }

            .history-active-date {
                // Highlight borders on mouseover
                &:hover,
                &:focus {
                    background: #000;
                }
            }

            // TODO: come up with better colors for predicted
            &.predicted {
                background: #555555;

                &.open {
                    background: #555555;
                }
            }

            &.nonOutage {
                background: #5cad5c;

                &.open {
                    background: #ad5c5c;
                }
            }

            &.outage {
                background: #006600;

                &.open {
                    background: #550000;
                }
            }
        }
    }
}

.tooltip {
    :global {
        .popover-header {
            background: #333;
            color: #fff;
            font-weight: 600;

            &.predicted {
                background: #555555;

                &.open {
                    background: #555555;
                }
            }

            &.nonOutage {
                background: #5cad5c;

                &.open {
                    background: #ad5c5c;
                }
            }

            &.outage {
                background: #006600;

                &.open {
                    background: #550000;
                }
            }
        }
    }
}
