@use '../../../../components/icons';
@use '../../../../components/colors';

.main {
    table {
        font-size: 0.9rem;

        th {
            padding-right: 1rem;
        }
        th,
        td {
            vertical-align: top;
        }
    }
}
